<template>
  <v-container class="login">
    <v-row>
      <v-col cols="12">
        <p>
          The app can only be logged into using the primary email address you
          registered with. Please enter it below to receive a login link. If you
          need help, please
          <a href="mailto:info@ce-tracker.app">info@ce-tracker.app</a>.
        </p>
        <LoginWithEmailLink v-if="emailOnly" />
        <LoginWithEmailAndPassword v-else />
      </v-col>

      <!-- <LoginWithGoogle /> -->
    </v-row>

    <v-footer class="mt-5">
      <v-spacer></v-spacer>
      <a @click="toggleEmailOnly">
        <span v-if="emailOnly">Admin Login</span>
        <span v-else>Login via Link</span>
      </a>
    </v-footer>
  </v-container>
</template>

<script setup>
// import LoginWithGoogle from "../components/LoginWithGoogle.vue";
import LoginWithEmailAndPassword from "../components/LoginWithEmailAndPassword.vue";
import LoginWithEmailLink from "../components/LoginWithEmailLink.vue";
import { ref } from "vue";

const emailOnly = ref(true);

const toggleEmailOnly = () => {
  emailOnly.value = !emailOnly.value;
};
// export default {
//   name: "ShowLogin",
//   components: { LoginWithEmailAndPassword, LoginWithEmailLink },
//   setup() {},
// };
</script>

<style>
.login {
  max-width: 800px;
}
</style>
