<template>
  <v-icon>mdi-account-star</v-icon>
</template>
<script setup>
// import { computed } from "vue";
import store from "@/store";
import { onMounted, onBeforeMount } from "vue";

// const user = computed(() => {
//   return store.state.user;
// });
console.log("setup admin account");

onBeforeMount(() => {
  console.log("onBeforeMount admin account");
});
onMounted(() => {
  console.log("onMounted admin account");
  console.log(store.state.reporters);
  // store.dispatch("bindCollection", {
  //   collection: "reporters",
  //   mutation: "updateReporters",
  // });

  // store.dispatch("bindCollection", {
  //   collection: "activity-log",
  //   mutation: "updateActivityLog",
  // });

  // store.dispatch("bindCollection", {
  //   collection: "ceRecords",
  //   mutation: "updateCERecords",
  // });
});
</script>
