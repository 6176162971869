import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import vuetify from "./plugins/vuetify";
import { VueMaskDirective } from "v-mask";
Vue.directive("mask", VueMaskDirective);

import Vuelidate from "vuelidate";
import { auth } from "./db";
import { isSignInWithEmailLink, signInWithEmailLink } from "firebase/auth";

Vue.config.productionTip = false;

let app;

//waits to see if there's a user before initially loading app
auth.onAuthStateChanged((_user) => {
  console.log("User state change. Current user is:", _user);

  if (_user !== null) {
    //make sure we have a user, then get the claims
    _user
      .getIdTokenResult()
      .then((idTokenResult) => {
        let user = { ..._user, claims: idTokenResult.claims };
        store.commit("setUser", user);
        // if the user is an admin, add the claims to the user in the store, then load admin data
        if (store.state.user.claims.admin) {
          console.log("user is admin");
          // console.log(user);
          // router.go({ name: "ShowReportersList" });
        } else {
          router.go(`reporter/${user.uid}`);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  } else if (isSignInWithEmailLink(auth, window.location.href)) {
    //we don't have a auth user, check the link to see it is a login link.
    console.log("is sign in with email link");

    // Additional state parameters can also be passed via URL.
    // This can be used to continue the user's intended action before triggering
    // the sign-in operation.
    // Get the email if available. This should be available if the user completes
    // the flow on the same device where they started it.
    let email = window.localStorage.getItem("emailForSignIn");
    if (!email) {
      // User opened the link on a different device. To prevent session fixation
      // attacks, ask the user to provide the associated email again. For example:
      email = window.prompt("Please provide your email for confirmation");
    }
    // The client SDK will parse the code from the link for you.
    signInWithEmailLink(auth, email, window.location.href)
      .then((result) => {
        // Clear email from storage.
        window.localStorage.removeItem("emailForSignIn");
        // You can access the new user via result.user
        console.log(result);

        // router.go(`reporter/${result.user.uid}`);
      })
      .catch((error) => {
        console.log(error);
        window.alert(
          "An error occured, sign in link is expired or is for a differnt user. Please try again."
        );
        router.push({ name: "ShowSignUp" });
        // Some error occurred, you can inspect the code: error.code
        // Common errors could be invalid email and invalid or expired OTPs.
      });
  } else {
    // We don't have a user and aren't logging in via link.

    //clear the store
    store.commit("clearStore");
    //clear the user
    store.commit("setUser", _user);
    // Then go to the login page.
    router.push({ name: "ShowLogin" });
  }

  if (!app) {
    new Vue({
      router,
      store,
      vuetify,
      Vuelidate,
      render: (h) => h(App),
    }).$mount("#app");
  }
});

// new Vue({
//   store,
//   router,
//   vuetify,
//   render: h => h(App)
// }).$mount('#app')
