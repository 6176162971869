<template>
  <v-app>
    <v-main>
      <NavBar />
      <ShowDialogs />
      <v-container class="mx04 mb4">
        <router-view />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import NavBar from "./components/NavBar.vue";
import ShowDialogs from "./components/Dialogs/ShowDialogs.vue";

export default {
  name: "App",
  data: () => ({
    //
  }),
  components: { NavBar, ShowDialogs },
};
</script>
