import { db } from "../db";
import {
  collection,
  query,
  where,
  and,
  or,
  orderBy,
  limit,
  getCountFromServer,
  startAfter,
  endBefore,
  onSnapshot,
} from "@firebase/firestore";

import { watchEffect } from "vue";

const state = () => {
  return {
    data: [],
    unsub: null,
    totalItems: 0,
    lastDoc: null,
    firstDoc: null,
    currentPage: 1,
    loading: false,
  };
};

const mutations = {
  setData(state, data) {
    state.data = data;
  },
  setLastDoc(state, lastDoc) {
    state.lastDoc = lastDoc;
  },
  setFirstDoc(state, firstDoc) {
    state.firstDoc = firstDoc;
  },
  setCurrentPage(state, currentPage) {
    state.currentPage = currentPage;
  },
  setTotalItems(state, totalItems) {
    state.totalItems = totalItems;
  },
  setLoading(state, loading) {
    state.loading = loading;
  },
  setUnsub(state, unsub) {
    state.unsub = unsub;
  },
};

const actions = {
  async bindCollectionPaginated({ commit, state }, payload) {
    // sample payload has  {collection, mutation, pageOptions, search}
    if (state.loading) return;

    console.log(
      `bindCollectionWithPaginationAndQuery called for ${payload.collection}`,
      payload
    );
    if (state.unsub) {
      console.log("unsub old data");
      state.unsub();
      //   commit("setUnsub", null)
    }
    commit("setLoading", true);

    let q;
    let qCount;
    try {
      const collectionRef = collection(db, payload.collection);

      let order;
      if (!payload.pageOptions.sortDesc.length) {
        return;
      } else if (payload.pageOptions.sortDesc[0] === true) {
        order = orderBy(payload.pageOptions.sortBy[0], "desc");
      } else {
        order = orderBy(payload.pageOptions.sortBy[0], "asc");
      }

      let qWhere;
      //setup qWhere based on search params
      if (payload.search.text != "" && !payload.search.exact) {
        //set orderBy search term
        order = orderBy(payload.search.field);
        qWhere = or(
          //query as is
          and(
            where(payload.search.field, ">=", payload.search.text),
            where(payload.search.field, "<=", payload.search.text + "\uf8ff")
          ),
          //capitalize first letter
          and(
            where(
              payload.search.field,
              ">=",
              payload.search.text.charAt(0).toUpperCase() +
                payload.search.text.slice(1)
            ),
            where(
              payload.search.field,
              "<=",
              payload.search.text.charAt(0).toUpperCase() +
                payload.search.text.slice(1) +
                "\uf8ff"
            )
          ),
          // lowercase
          and(
            where(
              payload.search.field,
              ">=",
              payload.search.text.toLowerCase()
            ),
            where(
              payload.search.field,
              "<=",
              payload.search.text.toLowerCase() + "\uf8ff"
            )
          )
        );
      } else if (payload.search.text != "") {
        qWhere = where(payload.search.field, "==", payload.search.text);
      }

      let docLimit = limit(payload.pageOptions.itemsPerPage);

      if (payload.search.text !== "" && payload.search != undefined) {
        //add qWhere to query

        qCount = query(collectionRef, qWhere, order);
        q = query(collectionRef, qWhere, order, docLimit);
        let count = await getCountFromServer(qCount);
        commit("setTotalItems", count.data().count);

        if (payload.pageOptions.page > state.currentPage) {
          //   console.log(state.lastDoc);
          q = query(
            collectionRef,
            qWhere,
            order,
            docLimit,
            startAfter(state.lastDoc)
          );
        }
        if (payload.pageOptions.page < state.currentPage) {
          q = query(
            collectionRef,
            qWhere,
            order,
            docLimit,
            endBefore(state.firstDoc)
          );
        }
      } else {
        //query with no search terms
        qCount = query(collectionRef, order);
        q = query(collectionRef, order, docLimit);

        let count = await getCountFromServer(qCount);
        commit("setTotalItems", count.data().count);

        if (payload.pageOptions.page > state.currentPage) {
          console.log(state.lastDoc);
          q = query(collectionRef, order, docLimit, startAfter(state.lastDoc));
        }
        if (payload.pageOptions.page < state.currentPage) {
          q = query(collectionRef, order, docLimit, endBefore(state.firstDoc));
        }
      }
      let items = [];
      const unsub = onSnapshot(q, (snap) => {
        console.log("snapshot");
        snap.docChanges().forEach((change) => {
          let index = items.findIndex(({ id }) => {
            // console.log(id);
            // console.log(id === change.doc.id);
            return id === change.doc.id;
          });
          if (change.type === "added") {
            console.log("Added Doc:", payload.collection, change.doc.data());
            items.push({ id: change.doc.id, ...change.doc.data() });
          }
          if (change.type === "modified") {
            console.log(items);
            console.log("Modified doc:", payload.collection, change.doc.data());
            // items[index] = { ...change.doc.data() };  Computed cannot decect this when rendering, must splice
            items.splice(index, 1, { id: change.doc.id, ...change.doc.data() });
            console.log(items);
          }
          if (change.type === "removed") {
            console.log("Removed Doc:", payload.collection, change.doc.data());
            items.splice(index, 1);
          }
          commit("setData", items);
        });
        const lastDoc = snap.docs[snap.docs.length - 1];
        commit("setLastDoc", lastDoc);
        const firstDoc = snap.docs[0];
        commit("setFirstDoc", firstDoc);
        commit("setCurrentPage", payload.pageOptions.page);
        // commit(payload.mutation, items, { root: true });
        // console.log(`global/${payload.mutation} store updated`);
      });
      commit("setUnsub", unsub);
      watchEffect((onCleanup) => {
        onCleanup(() => {
          console.log(`unsubscribing ${payload.collection}`);
          unsub();
        });
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      commit("setLoading", false);
    }
  },
};
export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
