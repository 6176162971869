<template>
  <v-data-table
    v-if="activityItems"
    :footer-props="{ 'items-per-page-options': [10, 20, 30] }"
    show-expand
    :options.sync="options"
    :search="search"
    :headers="headers"
    :items="activityItems"
    :loading="loading"
    :server-items-length="totalItems"
    @update:options="updateOptions"
  >
    <template v-slot:top>
      <v-toolbar elevation="0">
        <v-toolbar-title>Activity Log</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-text-field
          solo
          dense
          hide-details
          label="Search Record ID"
          prepend-inner-icon="mdi-magnify"
          v-model="search"
        ></v-text-field>
        <v-btn @click="updateOptions">Search</v-btn>
      </v-toolbar>
    </template>

    <template v-slot:[`item.timestamp`]="{ item }">
      {{ format(item.timestamp, "PPpp") }}
    </template>

    <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length">
        <v-row>
          <v-col cols="12" md="12" sm="12">
            <!-- <v-treeview :items="item"></v-treeview> -->
            New Data:
            <ul>
              <li v-for="key in Object.keys(item)" :key="key">
                {{ key }} : {{ item[key] }}
              </li>
            </ul>
          </v-col>
        </v-row>
      </td>
    </template>
  </v-data-table>
</template>

<script setup>
import store from "@/store";
import { ref, computed } from "vue";
import { format } from "date-fns";

// store.dispatch("bindCollection", {
//   collection: "activity-log",
//   mutation: "updateActivityLog",
// });

const headers = [
  { text: "Activity", value: "activity" },
  { text: "Record Type", value: "collection" },
  { text: "Record", value: "record" },
  { text: "Time", value: "timestamp" },

  { text: "Editor", value: "user.displayName" },
];

const search = ref("");

const options = ref({
  groupBy: [],
  groupDesc: [],
  itemsPerPage: 10,
  multiSort: false,
  mustSort: false,
  page: 1,
  sortBy: ["timestamp"],
  sortDesc: [true],
});

// const activityItems = computed(() => {
//   return store.state.activityLog.map((item) => {
//     return { ...item, timestamp: new Date(item.timestamp.seconds * 1000) };
//   });
// });

const activityItems = computed(() => {
  return store.state.activity.data.map((item) => {
    return { ...item, timestamp: new Date(item.timestamp.seconds * 1000) };
  });
});

const loading = computed(() => {
  return store.state.activity.loading;
});

const totalItems = computed(() => {
  return store.state.activity.totalItems;
});

const updateOptions = () => {
  // Dispatch the action to fetch data
  store.dispatch("activity/fetchData", { options, search });
};
</script>
