<template>
  <v-dialog
    v-model="dialog.visible"
    @click:outside="closeDialog"
    :retain-focus="false"
    max-width="800px"
  >
    <AddressEditDialog
      v-if="dialog.type === 'ADDRESS'"
      :editData="dialog.editData"
    />

    <ReporterInfoFormDialog
      v-if="dialog.type === 'USERRECORD'"
      :editData="dialog.editData"
    />

    <NotesEditDialog
      v-if="dialog.type === 'USERNOTES'"
      :editData="dialog.editData"
    />

    <CECreditsFormDialog
      v-if="dialog.type === 'CERECORD'"
      :editData="dialog.editData"
    />
  </v-dialog>
</template>
<script setup>
import { computed } from "vue";
import store from "@/store";
import AddressEditDialog from "@/components/Dialogs/AddressEditDialog.vue";
import NotesEditDialog from "@/components/Dialogs/NotesEditDialog.vue";
import ReporterInfoFormDialog from "@/components/Dialogs/ReporterInfoFormDialog.vue";
import CECreditsFormDialog from "@/components/Dialogs/CECreditsFormDialog.vue";

const closeDialog = () => {
  store.commit("setDialogVisible", false);
};

const dialog = computed(() => {
  return store.state.dialog;
});
</script>
