<template>
  <v-card>
    <v-alert v-if="errorMessage" :text="true" type="error" variant="tonal">{{
      errorMessage
    }}</v-alert>
    <v-card-title>
      <span class="text-h5">{{ formTitle }}</span>
    </v-card-title>

    <v-card-text>
      <!-- <v-row>
          <v-col cols="12" sm="12" md="12">
            <v-autocomplete
              v-model="formData.reporter"
              :items="reporters"
              item-text="searchName"
              return-object
              label="Reporter"
              required
              class="d-flex"
            ></v-autocomplete>
          </v-col>
        </v-row> -->
      <div v-if="showLookup">
        <ShowFindReporter />
      </div>
      <div v-else>
        <v-card-text>
          <v-form>
            <v-row>
              <v-col cols="12" sm="12" md="5">
                <v-text-field
                  v-model="formData.reporter.first_name"
                  label="First Name"
                  :disabled="true"
                  required
                ></v-text-field
              ></v-col>
              <v-col cols="12" sm="12" md="5"
                ><v-text-field
                  v-model="formData.reporter.last_name"
                  label="Last Name"
                  :disabled="true"
                  required
                ></v-text-field
              ></v-col>
              <!-- <v-col cols="12" sm="12" md="2"
            ><v-text-field
              v-model="formData.reporter.suffix"
              label="Suffix"
              :disabled="true"
              required
            ></v-text-field
          ></v-col> -->
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="6">
                <v-text-field
                  v-model="formData.reporter.CCR"
                  label="CCR"
                  :disabled="true"
                  required
                ></v-text-field
              ></v-col>
              <v-col cols="12" sm="12" md="6">
                <v-text-field
                  v-model="formData.reporter.CDR"
                  label="CDR"
                  :disabled="true"
                  required
                ></v-text-field
              ></v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="4">
                <v-text-field
                  v-model="formData.seminar_title"
                  required
                  label="Seminar Title"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="2">
                <v-text-field
                  v-model="formData.date_earned"
                  required
                  label="Date Earned"
                  type="date"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="2">
                <v-text-field
                  v-model="formData.cycle_code"
                  required
                  label="Cycle"
                  :disabled="true"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="2">
                <v-text-field
                  v-model="formData.credits"
                  required
                  label="Credits"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="2">
                <v-checkbox
                  v-model="formData.isEthics"
                  required
                  label="Ethics"
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="close"
            :disabled="isPending"
          >
            Cancel
          </v-btn>
          <v-btn
            v-if="editData.index === -1 && !showLookup"
            @click="resetLookup"
            color="blue darken-1"
            text
            >Pick Different Reporter</v-btn
          >
          <v-btn
            color="blue darken-1"
            text
            @click="save"
            :disabled="isPending || v$.$invalid"
          >
            Save
          </v-btn>
        </v-card-actions>
      </div>
    </v-card-text>
  </v-card>
</template>

<script setup>
import store from "@/store/";
import { ref, watchEffect } from "vue";
import { format, parseISO } from "date-fns";

import useVuelidate from "@vuelidate/core";
import { required, numeric } from "@vuelidate/validators";

import ShowFindReporter from "../ShowFindReporter.vue";

const isPending = ref(false);
const errorMessage = ref(null);

const props = defineProps({
  editData: Object,
});

const showLookup = ref(true);
const resetLookup = () => {
  showLookup.value = true;
  store.commit("setNewCERecord", null);
};
const formData = ref({
  reporter: {},
  cycle_code: "",
  date_earned: "",
  seminar_title: "",
  credits: "",
  isEthics: "",
});

const defaultItem = {
  reporter: {},
  cycle_code: "",
  date_earned: "",
  seminar_title: "",
  credits: "",
  isEthics: "",
};

const formTitle = ref("");

watchEffect(() => {
  if (parseISO(formData.value.date_earned)) {
    let earnedYear = parseISO(formData.value.date_earned).getFullYear();
    // if the year is odd, cycle is year - nextyear,
    if (earnedYear % 2 === 1) {
      let nextYear = Number(earnedYear) + 1;
      formData.value.cycle_code = `${earnedYear}-${nextYear}`;
    } else if (earnedYear % 2 === 0) {
      let lastYear = Number(earnedYear) - 1;
      formData.value.cycle_code = `${lastYear}-${earnedYear}`;
    } else {
      return "";
    }
  }
});

watchEffect(() => {
  //   console.log("watchEffet", props.editData.index);
  if (props.editData.index === -1) {
    formData.value = Object.assign({}, defaultItem);
    formTitle.value = `Create New ${props.editData.fieldLabel}`;
    showLookup.value = "true";
  } else {
    console.log(props.editData.payload);
    formData.value = Object.assign(
      {},
      {
        ...props.editData.payload,
        date_earned: format(
          props.editData.payload.date_earned.toDate(),
          "yyyy-MM-dd"
        ),
      }
    );
    showLookup.value = false;
    console.log(formData.value);
    formTitle.value = `Edit ${props.editData.fieldLabel}`;
  }
});

watchEffect(() => {
  if (store.state.newCERecord != null) {
    showLookup.value = false;
    formData.value = Object.assign(
      {},
      {
        ...defaultItem,
        reporter: { ...store.state.newCERecord },
      }
    );
  }
});

const close = () => {
  store.commit("setDialogVisible", false);
};

// const first_nameErrors = computed(() => {
//   let errors = [];
//   if (v$.value.first_name.$errors.length) {
//     errors.push("Field is required");
//     // v$.value.email.$errors.forEach(error => {
//     //     errors.push('error.$message')
//     // })
//   }
//   return errors;
// });

// const last_nameErrors = computed(() => {
//   let errors = [];
//   if (v$.value.last_name.$errors.length) {
//     errors.push("Field is required");
//     // v$.value.email.$errors.forEach(error => {
//     //     errors.push('error.$message')
//     // })
//   }
//   return errors;
// });

const rules = {
  reporter: { required },
  date_earned: { required },
  seminar_title: { required },
  credits: { required, numeric },
};

const v$ = useVuelidate(rules, formData);

// const fireFunctions = getFunctions();

const save = async () => {
  errorMessage.value = null;
  console.log(formData);
  isPending.value = true;
  v$.value.$touch();
  let earnedTimestamp = parseISO(formData.value.date_earned);
  if (!v$.value.$invalid) {
    //submit logic goes here

    if (props.editData.index === -1) {
      // new document
      let payload = {
        collection: "ceRecords",
        doc: {
          ...formData.value,
          date_earned: earnedTimestamp,
        },
      };
      try {
        let result = await store.dispatch("dbCreate", payload);
        console.log(result);
        isPending.value = false;
        close();
      } catch (error) {
        errorMessage.value = error.message;
        isPending.value = false;
        console.log(error);
      }
    } else {
      //update document

      let payload = {
        collection: "ceRecords",
        doc: {
          ...formData.value,
          date_earned: earnedTimestamp,
        },
        id: props.editData.recordID,
      };

      try {
        let result = await store.dispatch("dbUpdate", payload);
        console.log(result);
        isPending.value = false;
        close();
      } catch (error) {
        errorMessage.value = error.message;
        isPending.value = false;
        console.log(error);
      }
    }
  }
};

// const reporters = computed(() => {
//   return store.state.reporters.data.map((reporter) => {
//     let searchName =
//       reporter.first_name + " " + reporter.last_name + " (" + reporter.id + ")";
//     //search name for the autocomplete field.
//     //don't need to save the whole reporter object to the CE Record, so slim it down below.
//     return {
//       first_name: reporter.first_name,
//       last_name: reporter.last_name,
//       id: reporter.id,
//       email: reporter.email,
//       CCR: reporter.CCR ? reporter.CCR : "",
//       CDR: reporter.CDR ? reporter.CDR : "",
//       searchName,
//     };
//   });
// });
</script>

<style></style>
