import Vue from "vue";
import Vuex from "vuex";

import mutations from "./mutations";
import actions from "./actions";
import getters from "./getters";
import activityModule from "./activityModule";
import bindCollectionPaginated from "./bindCollectionPaginated";

const initialState = () => {
  return {
    user: null,
    // reporters: [],
    reporter: null,
    newCERecord: null,
    dialog: {
      visible: false,
      type: null,
      editData: null,
    },
    // activityLog: [],
    // ceRecords: [],
    reporterCERecords: [],
  };
};

Vue.use(Vuex);

export default new Vuex.Store({
  state: initialState(),
  getters: getters,
  mutations: mutations,
  actions: actions,
  modules: {
    activity: activityModule,
    ceCredits: bindCollectionPaginated,
    reporters: bindCollectionPaginated,
  },
});
