// store/firestoreModule.js
import { db } from "../db";
import {
  collection,
  query,
  where,
  limit,
  getDocs,
  orderBy,
  getCountFromServer,
  startAfter,
  endBefore,
} from "@firebase/firestore";

const state = {
  data: [],
  totalItems: 0,
  lastDoc: null,
  firstDoc: null,
  currentPage: 1,
  loading: false,
};

const mutations = {
  setData(state, data) {
    state.data = data;
  },
  setLastDoc(state, lastDoc) {
    state.lastDoc = lastDoc;
  },
  setFirstDoc(state, firstDoc) {
    state.firstDoc = firstDoc;
  },
  setCurrentPage(state, currentPage) {
    state.currentPage = currentPage;
  },
  setTotalItems(state, totalItems) {
    state.totalItems = totalItems;
  },
  setLoading(state, loading) {
    state.loading = loading;
  },
};

const actions = {
  async fetchData({ commit, state }, { options, search }) {
    options = options.value;
    search = search.value;
    console.log("options", options);
    console.log("search", search);
    // console.log("page", page);

    if (state.loading) return;

    commit("setLoading", true);

    try {
      let collectionRef = collection(db, "activity-log");
      let order;
      if (!options.sortDesc.length) {
        return;
      } else if (options.sortDesc[0] === true) {
        order = orderBy(options.sortBy[0], "desc");
      } else {
        order = orderBy(options.sortBy[0], "asc");
      }

      let docLimit = limit(options.itemsPerPage);
      let q;
      let qCount;
      if (search !== "" && search != undefined) {
        qCount = query(collectionRef, where("record", "==", search), order);
        q = query(
          collectionRef,
          where("record", "==", search),
          order,
          limit(10)
        );

        let count = await getCountFromServer(qCount);
        commit("setTotalItems", count.data().count);

        if (options.page > state.currentPage) {
          console.log(state.lastDoc);
          q = query(
            collectionRef,
            where("record", "==", search),
            order,
            docLimit,
            startAfter(state.lastDoc)
          );
        }
        if (options.page < state.currentPage) {
          q = query(
            collectionRef,
            where("record", "==", search),
            order,
            docLimit,
            endBefore(state.firstDoc)
          );
        }
      } else {
        qCount = query(collectionRef, order);
        q = query(collectionRef, order, docLimit);

        let count = await getCountFromServer(qCount);
        commit("setTotalItems", count.data().count);

        if (options.page > state.currentPage) {
          console.log(state.lastDoc);
          q = query(collectionRef, order, docLimit, startAfter(state.lastDoc));
        }
        if (options.page < state.currentPage) {
          q = query(collectionRef, order, docLimit, endBefore(state.firstDoc));
        }
      }

      const snapshot = await getDocs(q);
      console.log("activity snap", snapshot);
      const data = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      commit("setData", data);

      const lastDoc = snapshot.docs[snapshot.docs.length - 1];
      commit("setLastDoc", lastDoc);
      const firstDoc = snapshot.docs[0];
      commit("setFirstDoc", firstDoc);
      commit("setCurrentPage", options.page);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      commit("setLoading", false);
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
