//import firebase from 'firebase/app'
import "firebase/firestore";

const mutations = {
  setUser(state, user) {
    console.log("setUser");
    state.user = user;
  },

  setDialogVisible(state, value) {
    // console.log("dialogVisible:", value);
    if (value == false) {
      //if closing dialog, clear data
      state.dialog.type = null;
      state.dialog.editData = null;
    }
    state.dialog.visible = value;
  },

  setDialogType(state, payload) {
    console.log(payload);
    state.dialog.type = payload.type;
    state.dialog.editData = payload.editData;
  },

  clearStore(state) {
    console.log("clearing store data");
    state.reporters = [];
    state.reporter = "";
    state.reporterCERecords = [];
    // state.activity.data = [];
    // state.ceRecords.data = [];
    // state.reporters.data = [];
  },

  updateReporters(state, reporters) {
    console.log("update reporters list");
    state.reporters = reporters;
  },

  updateCERecords(state, ceRecords) {
    console.log("update CE Records list");
    state.ceRecords = ceRecords;
  },

  updateReporterCERecords(state, ceRecords) {
    console.log("update CE Records list");
    state.reporterCERecords = ceRecords;
  },

  updateReporter(state, reporter) {
    console.log("update reporter");
    state.reporter = reporter;
  },

  updateActivityLog(state, activityLog) {
    console.log("update activity log");
    state.activityLog = activityLog;
  },

  setNewCERecord(state, newCERecord) {
    console.log("set newCERecord Reporter");
    state.newCERecord = newCERecord;
  },
};
export default mutations;
