<template>
  <v-row>
    <v-col cols="12">
      <p>Home View</p>
      <p>Text to welcome and then explain things goes here.</p>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "HomeView",
};
</script>
