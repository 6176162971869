<template>
  <v-icon>mdi-account-cicle-outline</v-icon>
</template>
<script setup>
// import { computed } from "vue";
import store from "@/store";
import { onMounted, onBeforeMount } from "vue";

// const user = computed(() => {
//   return store.state.user;
// });
console.log("setup standard account");

onBeforeMount(() => {
  console.log("onBeforeMount standard account");
});
onMounted(() => {
  console.log("onMounted standard account");
  store.dispatch("bindDocument", {
    collection: "reporters",
    document: store.state.user.uid,
    mutation: "updateReporter",
  });
});
</script>
