<template>
  <div class="LoginWithEmailLink mx-auto">
    <v-alert v-if="errorMessage" :text="true" type="error" variant="tonal">{{
      errorMessage
    }}</v-alert>

    <v-alert
      v-if="emailSentMessage"
      :text="true"
      type="success"
      variant="tonal"
      >{{ emailSentMessage }}</v-alert
    >

    <v-form id="Email" @submit.prevent="handleSubmit">
      <v-text-field
        v-model="userEmail"
        :error-messages="emailErrors"
        label="E-mail"
        required
        @blur="v$.userEmail.$touch()"
      ></v-text-field>
      <v-btn type="submit" :disabled="isPending || v$.$invalid">Login</v-btn>
    </v-form>
  </div>
</template>

<script>
import { auth } from "@/db";
import { sendSignInLinkToEmail } from "firebase/auth";
import { ref, computed } from "vue";
import useVuelidate from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
import { getFunctions, httpsCallable } from "firebase/functions";

export default {
  name: "LoginWithEmailLink",
  setup() {
    const isPending = ref(false);
    const errorMessage = ref(null);
    const emailSentMessage = ref(null);

    const userEmail = ref("");

    const emailErrors = computed(() => {
      let errors = [];
      if (v$.value.userEmail.$errors.length) {
        errors.push("Please enter a valid email address.");
        // v$.value.userEmail.$errors.forEach(error => {
        //     errors.push('error.$message')
        // })
      }
      return errors;
    });

    const rules = {
      userEmail: { required, email },
    };

    let v$ = useVuelidate(rules, { userEmail });

    const fireFunctions = getFunctions();
    const checkUserEmail = httpsCallable(fireFunctions, "checkUserEmail");

    const actionCodeSettings = {
      // URL you want to redirect back to. The domain (www.example.com) for this
      // URL must be in the authorized domains list in the Firebase Console.
      url: process.env.VUE_APP_DOMAIN,
      // This must be true.
      handleCodeInApp: true,
    };

    const handleSubmit = async () => {
      errorMessage.value = null;
      isPending.value = true;
      //look up email by auth, if it exists send sign in with email link
      try {
        let response = await checkUserEmail(userEmail.value);
        console.log(response.data);
        if (response.data) {
          try {
            await sendSignInLinkToEmail(
              auth,
              userEmail.value,
              actionCodeSettings
            );
            emailSentMessage.value =
              "Link Sent.  Please check your email for your login link.";
            window.localStorage.setItem("emailForSignIn", userEmail.value);
            console.log("sent:", userEmail.value);
          } catch (error) {
            console.log(error);
            errorMessage.value = error.message;
          }
        } else {
          errorMessage.value = "Cannot email login link, user email not found.";
          isPending.value = false;
        }
      } catch (error) {
        errorMessage.value = error;
        isPending.value = false;
      }
    };

    return {
      isPending,
      errorMessage,
      emailSentMessage,
      userEmail,
      emailErrors,
      v$,
      handleSubmit,
    };
  },
};
</script>

<style>
.LoginWithEmailLink {
  max-width: 500px;
}
</style>
