<template>
  <v-card>
    <v-alert v-if="errorMessage" :text="true" type="error" variant="tonal">{{
      errorMessage
    }}</v-alert>
    <v-card-title>
      <span class="text-h5">{{ formTitle }}</span>
    </v-card-title>

    <v-card-text>
      <v-form>
        <v-row>
          <v-col cols="12" sm="1" md="1"
            ><v-text-field
              v-model="formData.salutation"
              :error-messages="salutationErrors"
              label="Salutation"
              @blur="v$.salutationErrors.$touch()"
            ></v-text-field
          ></v-col>
          <v-col cols="12" sm="5" md="3"
            ><v-text-field
              v-model="formData.first_name"
              :error-messages="first_nameErrors"
              label="First Name"
              required
              @blur="v$.first_name.$touch()"
            ></v-text-field></v-col
          ><v-col cols="12" sm="5" md="3"
            ><v-text-field
              v-model="formData.middle_name"
              :error-messages="middle_nameErrors"
              label="Middle Name"
              @blur="v$.middle_name.$touch()"
            ></v-text-field
          ></v-col>
          <v-col cols="12" sm="5" md="4"
            ><v-text-field
              v-model="formData.last_name"
              :error-messages="last_nameErrors"
              label="Last Name"
              required
              @blur="v$.last_name.$touch()"
            ></v-text-field
          ></v-col>

          <v-col cols="12" sm="2" md="1"
            ><v-text-field
              v-model="formData.suffix"
              :error-messages="suffixErrors"
              label="Suffix"
              @blur="v$.suffix.$touch()"
            ></v-text-field
          ></v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="12" md="6"
            ><v-text-field
              v-model="formData.email"
              :error-messages="emailErrors"
              label="Primary E-mail (will be used for login)"
              required
              @blur="v$.email.$touch()"
            ></v-text-field
          ></v-col>

          <v-col cols="12" sm="12" md="6"
            ><v-text-field
              v-model="formData.email2"
              :error-messages="email2Errors"
              label="Secondary E-mail"
              @blur="v$.email2.$touch()"
            ></v-text-field
          ></v-col>
        </v-row>
        <v-row>
          <!-- <v-col cols="12" sm="12" md="6">
            <v-text-field-simplemask
              v-model="formData.phone"
              label="Phone (TXT/SMS alerts for login)"
              v-bind:properties="{
                prefix: '',
                suffix: '',
                readonly: false,
                disabled: false,
                outlined: false,
                clearable: true,
                placeholder: '',
              }"
              v-bind:options="{
                inputMask: '+# (###) ###-####',
                outputMask: '###########',
                empty: null,
                applyAfter: false,
                alphanumeric: true,
                lowerCase: false,
              }"
            />
          </v-col> -->
          <v-col cols="12" sm="12" md="6"
            ><v-text-field
              v-mask="'# (###) ###-####'"
              v-model="formData.phone"
              :error-messages="phoneErrors"
              label="Phone (TXT/SMS alerts for login)"
              clearable
              @blur="v$.phone.$touch()"
            ></v-text-field
          ></v-col>
          <v-col cols="12" sm="12" md="6"
            ><v-text-field
              v-mask="'# (###) ###-####'"
              v-model="formData.phone2"
              :error-messages="phone2Errors"
              label="Secondary phone"
              clearable
              @blur="v$.phone2.$touch()"
            ></v-text-field
          ></v-col>
          <!-- <v-col cols="12" sm="12" md="6">
            <v-text-field-simplemask
              v-model="formData.phone2"
              label="Phone 2"
              v-bind:properties="{
                prefix: '',
                suffix: '',
                readonly: false,
                disabled: false,
                outlined: false,
                clearable: true,
                placeholder: '',
              }"
              v-bind:options="{
                inputMask: '+# (###) ###-####',
                outputMask: '###########',
                empty: null,
                applyAfter: false,
                alphanumeric: true,
                lowerCase: false,
              }"
              :error-messages="phone2Errors"
          /></v-col> -->

          <!-- <v-col cols="12" sm="12" md="4"
          ><v-text-field
            v-model="formData.phone"
            label="Telephone"
            country="US"
            :onlyCountries="['US']"
            countryIconMode="svg"
            displayFormat="national"
            invalid-message="Phone number must be a valid phone number (example: (504)-555-5555)."
          ></v-text-field
        ></v-col> -->
        </v-row>

        <v-row>
          <v-col cols="12" sm="12" md="4"
            ><v-text-field
              v-model="formData.DOB"
              :error-messages="DOBErrors"
              label="Date of Birth"
              @blur="v$.DOB.$touch()"
              type="date"
            ></v-text-field
          ></v-col>
          <v-spacer></v-spacer>
          <v-col cols="12" sm="5" md="4">
            <v-text-field
              v-model="formData.other_name"
              :error-messages="other_nameErrors"
              label="Other Name"
              @blur="v$.other_name.$touch()"
            ></v-text-field
          ></v-col>
        </v-row>

        <!-- <v-btn type="submit" :disabled="isPending || v$.$invalid"
          >Create Reporter</v-btn
        > -->
      </v-form>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        v-if="editData.index === 0"
        color="blue darken-1"
        text
        @click="close"
        :disabled="isPending"
      >
        Cancel
      </v-btn>
      <v-btn
        color="blue darken-1"
        text
        @click="save"
        :disabled="isPending || v$.$invalid"
      >
        Save
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script setup>
import store from "@/store/";
import { ref, watchEffect, computed } from "vue";
import { format, parseISO } from "date-fns";

import useVuelidate from "@vuelidate/core";
import { required, email, helpers } from "@vuelidate/validators";
// import store from "../../store";
// import fireFunctions from "../../db";
import { getFunctions, httpsCallable } from "firebase/functions";
import router from "../../router";
import convertToE164 from "../../composables/convertToE164";

import "flag-icons/css/flag-icons.min.css";

const isPending = ref(false);
const errorMessage = ref(null);
// const first_name = ref("");
// const last_name = ref("");
// const suffix = ref("");

const props = defineProps({
  editData: Object,
});

const formData = ref({
  email: "",
  email2: "",
  phone: "",
  phone2: "",
  salutation: "",
  middle_name: "",
  other_name: "",
  first_name: "",
  last_name: "",
  suffix: "",
  DOB: "",
  disciplinary_actions: {
    disciplinary_actions: "",
    filed_by: "",
    complaint_date: "",
    final_action: "",
    date_closed: "",
    keep_confidential: "",
  },
  notes: "",
  status: {
    status: "",
    status_date: "",
    status_imported: "",
  },
});

const defaultItem = {
  email: "",
  email2: "",
  phone: "",
  phone2: "",
  salutation: "",
  middle_name: "",
  other_name: "",
  first_name: "",
  last_name: "",
  suffix: "",
  DOB: "",
  disciplinary_actions: {
    disciplinary_actions: "",
    filed_by: "",
    complaint_date: "",
    final_action: "",
    date_closed: "",
    keep_confidential: "",
  },
  notes: "",
  status: {
    status: "",
    status_date: "",
    status_imported: "",
  },
};

const formTitle = ref("");
console.log(props.editData);

watchEffect(() => {
  //   console.log("watchEffet", props.editData.index);
  if (props.editData.index === -1) {
    formData.value = Object.assign({}, defaultItem);
    formTitle.value = `Create New ${props.editData.fieldLabel}`;
  } else {
    console.log(props.editData.payload);
    formData.value = Object.assign(
      {},
      {
        ...props.editData.payload,
        DOB: format(parseISO(props.editData.payload.DOB), "yyyy-MM-dd"),
        recordID: props.editData.recordID,
      }
    );
    console.log(formData.value);
    formTitle.value = `Edit ${props.editData.fieldLabel}`;
  }
});

const close = () => {
  store.commit("setDialogVisible", false);
};

const first_nameErrors = computed(() => {
  let errors = [];
  if (v$.value.first_name.$errors.length) {
    errors.push("Field is required");
    // v$.value.email.$errors.forEach(error => {
    //     errors.push('error.$message')
    // })
  }
  return errors;
});

const last_nameErrors = computed(() => {
  let errors = [];
  if (v$.value.last_name.$errors.length) {
    errors.push("Field is required");
    // v$.value.email.$errors.forEach(error => {
    //     errors.push('error.$message')
    // })
  }
  return errors;
});

const suffixErrors = computed(() => {
  let errors = [];
  if (v$.value.suffix.$errors.length) {
    errors.push("Suffix Must be Text");
    // v$.value.email.$errors.forEach(error => {
    //     errors.push('error.$message')
    // })
  }
  return errors;
});
const salutationErrors = computed(() => {
  let errors = [];
  if (v$.value.suffix.$errors.length) {
    errors.push("Suffix Must be Text");
    // v$.value.email.$errors.forEach(error => {
    //     errors.push('error.$message')
    // })
  }
  return errors;
});
const middle_nameErrors = computed(() => {
  let errors = [];
  if (v$.value.suffix.$errors.length) {
    errors.push("Suffix Must be Text");
    // v$.value.email.$errors.forEach(error => {
    //     errors.push('error.$message')
    // })
  }
  return errors;
});
const other_nameErrors = computed(() => {
  let errors = [];
  if (v$.value.suffix.$errors.length) {
    errors.push("Suffix Must be Text");
    // v$.value.email.$errors.forEach(error => {
    //     errors.push('error.$message')
    // })
  }
  return errors;
});

const emailErrors = computed(() => {
  let errors = [];
  if (v$.value.email.$errors.length) {
    errors.push("Please enter a valid email address.");
    // v$.value.email.$errors.forEach(error => {
    //     errors.push('error.$message')
    // })
  }
  return errors;
});

const email2Errors = computed(() => {
  let errors = [];
  if (v$.value.email2.$errors.length) {
    errors.push("Please enter a valid email address.");
    // v$.value.email.$errors.forEach(error => {
    //     errors.push('error.$message')
    // })
  }
  return errors;
});
const phoneErrors = computed(() => {
  let errors = [];
  if (v$.value.phone.$errors.length) {
    errors.push("Please enter a valid phone number.");
    // v$.value.email.$errors.forEach(error => {
    //     errors.push('error.$message')
    // })
  }
  return errors;
});
const phone2Errors = computed(() => {
  let errors = [];
  if (v$.value.phone2.$errors.length) {
    errors.push("Please enter a valid phone number.");
    // v$.value.email.$errors.forEach(error => {
    //     errors.push('error.$message')
    // })
  }
  return errors;
});

const DOBErrors = computed(() => {
  let errors = [];
  if (v$.value.DOB.$errors.length) {
    errors.push("Please enter a valid date.");
    // v$.value.email.$errors.forEach(error => {
    //     errors.push('error.$message')
    // })
  }
  return errors;
});

const libphonenumber = require("libphonenumber-js");
const isPhone = (value) => {
  // console.log("isPhone:", !helpers.req(value));
  // );
  return (
    !helpers.req(value) ||
    libphonenumber.parsePhoneNumberFromString(value, "US").isValid()
  );
};

const rules = {
  email: { required, email },
  email2: { email },
  phone: { isPhone },
  phone2: { isPhone },
  salutation: {},
  first_name: { required },
  middle_name: {},
  last_name: { required },
  suffix: {},
  other_name: {},
  DOB: {},
};

const v$ = useVuelidate(rules, formData);

const fireFunctions = getFunctions();

const save = async () => {
  errorMessage.value = null;
  console.log(formData);

  isPending.value = true;
  v$.value.$touch();
  if (!v$.value.$invalid) {
    //submit logic goes here
    let doc = {
      ...formData.value,
      DOB: parseISO(formData.value.DOB),
      phone: convertToE164(formData.value.phone),
      phone2: convertToE164(formData.value.phone2),
    };

    //FOR ADDING NEW USER
    if (props.editData.index === -1) {
      try {
        const addUser = httpsCallable(fireFunctions, "addUser");
        let result = await addUser(doc);
        console.log(result);
        router.push({
          name: "ShowReporterInfo",
          params: { id: result.data.uid },
        });
      } catch (error) {
        errorMessage.value = error.message;
        isPending.value = false;
        console.log(error);
      }
      // let payload = { doc, collection: "reporters" };
      // console.log("payload:", payload);
      // try {
      //   store.dispatch("dbCreate", payload);
      // } catch (error) {
      //   error.value = error.message;
      // }
    } else {
      try {
        const updateUser = httpsCallable(fireFunctions, "updateUser");
        let result = await updateUser(doc);
        console.log(result);
        close();
      } catch (error) {
        errorMessage.value = error.message;
        isPending.value = false;
        console.log(error);
      }
    }
  }

  // try {
  //   let payload = {
  //     collection: "reporters",
  //     id: props.editData.recordID,
  //     doc: { [props.editData.fieldName]: { ...formData.value.address } },
  //   };

  //   await store.dispatch("dbUpdate", payload);

  //   close();

  //   isProcessing.value = false;
  // } catch (error) {
  //   console.log(error);
  //   errorMessage.value = error.message;
  //   isProcessing.value = false;
  // }
};
</script>

<style></style>
