<template>
  <nav>
    <v-app-bar app clipped-left dense>
      <v-app-bar-nav-icon
        @click="drawer = !drawer"
        v-if="user"
      ></v-app-bar-nav-icon>
      <v-toolbar-title clipped class="grey--text"> CE Tracker </v-toolbar-title>
      <v-spacer></v-spacer>

      <div>
        <v-menu offset-y v-if="user" class="d-none d-md-inline">
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" class="mx-1"> Menu </v-btn>
          </template>
          <v-list nav>
            <v-list-item-group>
              <v-list-item
                v-for="link in links"
                link
                exact
                :key="link.text"
                :to="link.route"
              >
                <v-list-item-icon>
                  <v-icon>{{ link.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ link.text }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>

        <v-btn @click="signout" v-if="user">
          <span
            ><AdminUser v-if="user.claims.admin == true"></AdminUser>
            <StandardUser v-else></StandardUser>
          </span>
          <span class="d-none d-md-flex">Sign Out</span>
          <v-icon right>mdi-exit-to-app</v-icon>
        </v-btn>
      </div>
    </v-app-bar>

    <v-navigation-drawer
      v-if="user"
      v-model="drawer"
      app
      clipped
      class="primary"
    >
      <v-list v-if="user" two-line class="pt-4">
        <!-- <router-link :to="{name: 'Profile', params: { uid: user.uid} }"> -->
        <v-list-item>
          <v-list-item-avatar size="50">
            <v-icon v-if="!user.photoURL">mdi-account</v-icon>
            <img
              v-else
              :src="user.photoURL"
              :alt="user.displayName"
              referrerpolicy="no-referrer"
            />
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="white--text">
              {{ user.displayName }}
            </v-list-item-title>
            <v-list-item-subtitle class="white--text">
              {{ user.email }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <!-- </router-link> -->
      </v-list>

      <v-list nav>
        <v-list-item
          v-for="link in links"
          :key="link.text"
          :to="link.route"
          link
          exact
        >
          <v-list-item-icon>
            <v-icon class="white--text">{{ link.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="white--text">{{
              link.text
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </nav>
</template>

<script>
import { ref, computed } from "vue";
import store from "../store";
import AdminUser from "@/components/Auth/AdminUser.vue";
import StandardUser from "@/components/Auth/StandardUser.vue";

export default {
  name: "NavBar",
  components: { AdminUser, StandardUser },
  setup() {
    const drawer = ref(null);
    const user = computed(() => {
      return store.state.user;
    });
    const signout = async () => {
      await store.dispatch("logout").then(() => {
        console.log("signed out");
        // this.$router.push({name: 'Home'})
      });
    };
    const publicLinks = [
      {
        icon: "mdi-home",
        text: "Home",
        route: { name: "HomeView" },
      },
    ];

    let userLinks = computed(() => {
      if (user.value != null) {
        return [
          {
            icon: "mdi-account",
            text: "Reporter Info",
            route: {
              name: "ShowReporterInfo",
              params: { id: store.state.user.uid },
            },
          },
        ];
      } else return [];
    });

    const adminLinks = computed(() => {
      if (user.value != null && user.value.claims.admin == true) {
        return [
          {
            icon: "mdi-view-list-outline",
            text: "List Reporters",
            route: { name: "ShowReportersList" },
          },
          {
            icon: "mdi-pencil",
            text: "Create Reporter",
            route: { name: "ShowCreateReporter" },
          },
          {
            icon: "mdi-view-list-outline",
            text: "CE Credits",
            route: { name: "ShowCECredits" },
          },
          {
            icon: "mdi-view-list-outline",
            text: "User Record Activity",
            route: { name: "ShowActivityLog" },
          },
        ];
      } else return [];
    });

    const links = computed(() => {
      if (user.value != null) {
        if (
          user.value.claims.admin != null &&
          user.value.claims.admin == true
        ) {
          return publicLinks.concat(adminLinks.value);
        } else {
          return publicLinks.concat(userLinks.value);
        }
        // console.log(userLinks, adminLinks);
      } else {
        return publicLinks;
      }
    });
    // console.log(links.value);

    return { user, signout, drawer, links };
  },
};
</script>
