<template>
  <v-container class="reporterInfo row">
    <v-alert v-if="errorMessage" :text="text" type="error" variant="tonal">{{
      errorMessage
    }}</v-alert>

    <div v-if="reporters">
      <v-data-table
        show-expand
        :headers="headers"
        :items="reporters"
        :options.sync="options"
        :loading="loading"
        :server-items-length="totalItems"
        @update:options="fetchData"
      >
        <template v-slot:top>
          <v-toolbar elevation="0">
            <v-toolbar-title>Reporters</v-toolbar-title>
          </v-toolbar>
          <v-toolbar elevation="0">
            <v-form @submit.prevent="fetchData">
              <v-row>
                <v-col cols="3">
                  <v-select
                    dense
                    flat
                    label="Select Field"
                    v-model="searchField"
                    :items="headers"
                    item-text="text"
                    item-value="value"
                    return-object
                    single-line
                  ></v-select>
                </v-col>
                <v-col cols="5">
                  <v-text-field
                    flat
                    elevation="0"
                    dense
                    hide-details
                    clearable
                    label="Search Text"
                    v-model="searchText"
                    appendIcon="mdi-magnify"
                  ></v-text-field>
                </v-col>
                <v-col cols="2">
                  <v-checkbox
                    flat
                    elevation="0"
                    dense
                    v-model="searchExact"
                    label="Exact"
                  >
                  </v-checkbox>
                </v-col>
                <v-col cols="2">
                  <v-btn dense type="submit">Search</v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-toolbar>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <router-link
            :to="{ name: 'ShowReporterInfo', params: { id: item.id } }"
            >View More</router-link
          >
        </template>
      </v-data-table>
    </div>
    <div v-else>Loading...</div>
  </v-container>
</template>

<script setup>
import { ref, computed } from "vue";
import store from "../../store";
// import ShowAddCert from "@/components/ShowAddCert.vue";

// export default {
//   name: "ShowReportersList",
//   components: {},
//   props: [],
//   setup() {

const errorMessage = ref(null);
// const reporterInfo = ref(null)

const searchText = ref("");
const searchField = ref({});
const searchExact = ref(false);

const options = ref({
  groupBy: [],
  groupDesc: [],
  itemsPerPage: 10,
  multiSort: false,
  mustSort: false,
  page: 1,
  sortBy: ["last_name"],
  sortDesc: [true],
});

const headers = [
  { text: "First Name", value: "first_name" },
  { text: "Last Name", value: "last_name" },
  { text: "Primary Email", value: "email" },
  { text: "CCR", value: "CCR.certNumber" },
  { text: "CDR", value: "CDR.certNumber" },
  { text: "Status", value: "status.status" },

  {
    text: "actions",
    value: "actions",
    groupable: false,
    sortable: false,
    filterable: false,
  },
];

const reporters = computed(() => {
  return store.state.reporters.data.map((item) => {
    return { ...item };
  });
  // .map((reporter)=>{
  //   return {
  //     first_name:reporter.first_name,
  //     last_name: reporter.last_name,
  //     email: reporter.email
  //   }
  // })
});

const loading = computed(() => {
  return store.state.reporters.loading;
});

const totalItems = computed(() => {
  return store.state.reporters.totalItems;
});

const fetchData = () => {
  // console.log("search", searchText.value, searchField.value.value);
  // console.log("options", options.value);
  //  payload has  {collection, mutation, pageOptions, search}
  let payload = {
    collection: "reporters",
    mutation: "updateReporters",
    pageOptions: options.value,
    search: {
      text: searchText.value,
      field: searchField.value.value,
      exact: searchExact.value,
    },
  };

  store.dispatch("reporters/bindCollectionPaginated", payload);
};
// onBeforeUnmount(()=>{
//   unsub();
// })
//   return { headers, reporters, errorMessage };
// },
// };
</script>

<style></style>
