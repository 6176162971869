<template>
  <div v-if="isAdmin">
    <v-alert v-if="errorMessage" type="error" :text="true" variant="tonal">{{
      errorMessage
    }}</v-alert>

    <h3>Add {{ certType }} info</h3>
    <v-form @submit.prevent="addCert(certType)">
      <v-text-field
        v-model="certNumber"
        :error-messages="certNumberErrors"
        label="Add Cert Number"
        required
        @blur="v$.certNumber.$touch()"
      ></v-text-field>
      <v-btn type="submit">Add {{ certType }}</v-btn>
    </v-form>
  </div>
</template>

<script>
import { ref, computed } from "vue";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import store from "../store";
// import fireFunctions from "../../db";
import { getFunctions, httpsCallable } from "firebase/functions";
// import router from "../../router";

export default {
  name: "ShowCreateReporter",
  props: ["certType", "reporterId"],
  setup(props) {
    const isPending = ref(false);
    const errorMessage = ref(null);
    const certNumber = ref("");

    const certNumberErrors = computed(() => {
      let errors = [];
      if (v$.value.certNumber.$errors.length) {
        errors.push("Field is required");
        // v$.value.userEmail.$errors.forEach(error => {
        //     errors.push('error.$message')
        // })
      }
      return errors;
    });

    const rules = {
      certNumber: { required },
    };

    let v$ = useVuelidate(rules, { certNumber });

    const fireFunctions = getFunctions();
    const addCert = async (certType) => {
      console.log("addCert called");
      isPending.value = true;
      v$.value.$touch();
      if (!v$.value.$invalid) {
        //submit logic goes here
        let doc = {
          certType,
          certNumber: certNumber.value,
          reporterId: props.reporterId,
        };
        console.log(doc);

        try {
          const addCertToReporter = httpsCallable(
            fireFunctions,
            "addCertToReporter"
          );
          let result = await addCertToReporter(doc);
          console.log(result);
        } catch (error) {
          errorMessage.value = error.message;
          isPending.value = false;
          console.log(error);
        }
        // let payload = { doc, collection: "reporters" };
        // console.log("payload:", payload);
        // try {
        //   store.dispatch("dbCreate", payload);
        // } catch (error) {
        //   error.value = error.message;
        // }
      }
    };
    const isAdmin = store.state.user.claims.admin ? true : false;

    return {
      errorMessage,
      isPending,
      certNumber,
      v$,
      certNumberErrors,
      addCert,
      props,
      isAdmin,
    };
  },
};
</script>
