<template>
  <div>
    <v-card>
      <v-alert
        v-if="errorMessage"
        :text="errorMessage"
        type="error"
        variant="tonal"
      ></v-alert>

      <v-card-title>
        {{ fieldLabel }}
        <v-spacer></v-spacer>
        <v-card-actions v-if="address">
          <v-btn color="blue darken-1" text @click="editItem(address, 0)"
            >Edit</v-btn
          >
        </v-card-actions>

        <v-card-actions v-else>
          <v-btn color="blue darken-1" text @click="editItem(null, -1)"
            >Add</v-btn
          >
        </v-card-actions>
      </v-card-title>
      <div v-if="address">
        <v-card-text>
          <p v-if="fieldName == 'work_address'">
            Employer: {{ address.employer_name }}<br />
            Employer Type: {{ address.employer_type }}
          </p>
          <p>
            {{ address.line_1 }} <br />
            <span v-if="address.line_2">{{ address.line_2 }}<br /></span>
            {{ address.city }}, {{ address.state }} {{ address.postalCode }}

            <span v-if="fieldName == 'work_address'">
              <br />
              Phone: {{ address.work_phone }}<br />
              Fax: {{ address.work_fax }}</span
            >
          </p>
        </v-card-text>
      </div>
    </v-card>
  </div>
</template>

<script setup>
import { ref } from "vue";
import store from "@/store";

const props = defineProps({
  address: Object,
  fieldLabel: String,
  fieldName: String,
  recordID: String,
});

const errorMessage = ref(null);

const openDialog = (payload) => {
  store.commit("setDialogType", payload);
  store.commit("setDialogVisible", true);
};

const editItem = (item, index) => {
  console.log("editItem");
  let payload = {
    editData: {
      index,
      payload: Object.assign({}, item),
      fieldLabel: props.fieldLabel,
      fieldName: props.fieldName,
      recordID: props.recordID,
    },
    type: "ADDRESS",
  };

  console.log(payload);
  openDialog(payload);
};
</script>
