<template>
  <v-card>
    <v-alert v-if="errorMessage" :text="true" type="error" variant="tonal">{{
      errorMessage
    }}</v-alert>
    <v-card-title>
      <span class="text-h5">Edit Notes</span>
    </v-card-title>

    <v-card-text>
      <v-container>
        <v-row>
          <v-col cols="12">
            <v-textarea v-model="formData.notes"></v-textarea>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="blue darken-1" text @click="close" :disabled="isProcessing">
        Cancel
      </v-btn>
      <v-btn color="blue darken-1" text @click="save" :disabled="isProcessing">
        Save
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script setup>
import store from "@/store/";
import { ref, watchEffect } from "vue";
// import { format } from "date-fns";

const errorMessage = ref(null);

const props = defineProps({
  editData: Object,
});

const formData = ref({
  notes: "",
});
// const defaultItem = {
//   notes: "",
// };

watchEffect(() => {
  formData.value = Object.assign({}, props.editData.payload);
});

const close = () => {
  store.commit("setDialogVisible", false);
};

const isProcessing = ref(false);

const save = async () => {
  errorMessage.value = null;
  isProcessing.value = true;
  console.log(formData);

  try {
    let payload = {
      collection: "reporters",
      id: props.editData.recordID,
      doc: { ...formData.value },
    };

    await store.dispatch("dbUpdate", payload);

    close();

    isProcessing.value = false;
  } catch (error) {
    console.log(error);
    errorMessage.value = error.message;
    isProcessing.value = false;
  }
};
</script>

<style></style>
