<template>
  <v-card>
    <v-alert v-if="errorMessage" :text="true" type="error" variant="tonal">{{
      errorMessage
    }}</v-alert>
    <v-card-title>
      <span class="text-h5">{{ formTitle }}</span>
    </v-card-title>

    <v-card-text>
      <v-container>
        <v-row v-if="editData.fieldName === 'work_address'">
          <v-col cols="12" sm="6" md="6">
            <v-text-field
              v-model="formData.address.employer_name"
              label="Employer"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <v-text-field
              v-model="formData.address.employer_type"
              label="Employer Type"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <v-text-field
              v-model="formData.address.line_1"
              label="Address Line 1"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="12" md="12">
            <v-text-field
              v-model="formData.address.line_2"
              label="Address Line 2"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <v-text-field
              v-model="formData.address.city"
              label="City"
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="2" md="2">
            <v-text-field
              v-model="formData.address.state"
              label="State"
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="4" md="4">
            <v-text-field
              v-model="formData.address.postalCode"
              label="Zip"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row v-if="editData.fieldName === 'work_address'">
          <v-col cols="12" sm="6" md="6">
            <v-text-field
              v-mask="'# (###) ###-####'"
              v-model="formData.address.work_phone"
              :error-messages="work_phoneErrors"
              label="Work Phone"
              clearable
              @blur="v$.address.work_phone.$touch()"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <v-text-field
              v-mask="'# (###) ###-####'"
              v-model="formData.address.work_fax"
              :error-messages="work_faxErrors"
              label="Work Fax"
              clearable
              @blur="v$.address.work_fax.$touch()"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="blue darken-1" text @click="close" :disabled="isProcessing">
        Cancel
      </v-btn>
      <v-btn
        color="blue darken-1"
        text
        @click="save"
        :disabled="isProcessing || v$.$invalid"
      >
        Save
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script setup>
import store from "@/store/";
import { ref, computed, watchEffect } from "vue";
// import { format } from "date-fns";
import useVuelidate from "@vuelidate/core";
import { helpers } from "@vuelidate/validators";

const errorMessage = ref(null);

const props = defineProps({
  editData: Object,
});

const formData = ref({
  address: {
    line_1: "",
    line_2: "",
    city: "",
    state: "",
    postalCode: "",
  },
});
const defaultItem = {
  address: {
    line_1: "",
    line_2: "",
    city: "",
    state: "",
    postalCode: "",
  },
};
const defaultItemWork = {
  address: {
    employer_name: "",
    employer_type: "",
    line_1: "",
    line_2: "",
    city: "",
    state: "",
    postalCode: "",
    work_phone: "",
    work_fax: "",
  },
};
const formTitle = ref("");
// console.log(props.editData);
watchEffect(() => {
  //   console.log("watchEffet", props.editData.index);
  if (props.editData.index === -1) {
    formTitle.value = `New ${props.editData.fieldLabel}`;
    if (props.editData.fieldName === "work_address") {
      formData.value = Object.assign({}, defaultItemWork);
    } else {
      formData.value = Object.assign({}, defaultItem);
    }
  } else {
    console.log(props.editData.payload);
    formData.value = Object.assign(
      {},
      { address: { ...props.editData.payload } }
    );
    console.log(formData.value);
    formTitle.value = `Edit ${props.editData.fieldLabel}`;
  }
});

const work_phoneErrors = computed(() => {
  let errors = [];
  if (v$.value.address.work_phone.$errors.length) {
    errors.push("Please enter a valid phone number.");
    // v$.value.email.$errors.forEach(error => {
    //     errors.push('error.$message')
    // })
  }
  return errors;
});
const work_faxErrors = computed(() => {
  let errors = [];
  if (v$.value.address.work_fax.$errors.length) {
    errors.push("Please enter a valid phone number.");
    // v$.value.email.$errors.forEach(error => {
    //     errors.push('error.$message')
    // })
  }
  return errors;
});
const libphonenumber = require("libphonenumber-js");
const isPhone = (value) => {
  console.log("isPhone:", !helpers.req(value));

  return (
    !helpers.req(value) ||
    libphonenumber.parsePhoneNumberFromString(value, "US").isValid()
  );
};

const rules = {
  address: {
    work_phone: { isPhone },
    work_fax: { isPhone },
  },
};

const v$ = useVuelidate(rules, formData);

const close = () => {
  store.commit("setDialogVisible", false);
};

const isProcessing = ref(false);

const save = async () => {
  errorMessage.value = null;
  isProcessing.value = true;
  console.log(formData);

  try {
    let payload = {
      collection: "reporters",
      id: props.editData.recordID,
      doc: { [props.editData.fieldName]: { ...formData.value.address } },
    };

    await store.dispatch("dbUpdate", payload);

    close();

    isProcessing.value = false;
  } catch (error) {
    console.log(error);
    errorMessage.value = error.message;
    isProcessing.value = false;
  }
};
</script>

<style></style>
