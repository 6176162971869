import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { getAuth, connectAuthEmulator } from "firebase/auth";

// import "firebase/firebase-functions";

// Export types that exists in Firestore - Uncomment if you need them in your app
// const { Timestamp } = firebase.firestore
// export { Timestamp, GeoPoint }
const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTHDOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
};

//init firebase
const firebaseApp = initializeApp(firebaseConfig);

//init firestore service
const db = getFirestore(firebaseApp);
// const db = firebaseApp.firestore();
const auth = getAuth(firebaseApp);
// const timestamp = firebase.firestore.FieldValue.serverTimestamp();
const fireFunctions = getFunctions(firebaseApp, "us-central1");

const analytics = getAnalytics(firebaseApp);

console.log(process.env.VUE_APP_USE_FIRE_EMULATOR);

if (process.env.VUE_APP_USE_FIRE_EMULATOR == "TRUE") {
  console.log("using emulator");
  connectFirestoreEmulator(db, "localhost", 8080);
  connectFunctionsEmulator(fireFunctions, "localhost", 5001);
  connectAuthEmulator(auth, "http://localhost:9099");
}

export { db, auth, firebaseApp, fireFunctions, analytics };
