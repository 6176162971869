const libphonenumber = require("libphonenumber-js");

function convertToE164(phoneNumber) {
  try {
    phoneNumber = phoneNumber.toString();
    // Parse the phone number
    const parsedPhoneNumber = libphonenumber.parsePhoneNumberFromString(
      phoneNumber,
      "US"
    );

    // Check if the phone number is valid
    if (parsedPhoneNumber && parsedPhoneNumber.isValid()) {
      // Convert the phone number to E.164 format
      return parsedPhoneNumber.formatInternational();
    } else {
      console.error("Invalid phone number:", phoneNumber);
      return null;
    }
  } catch (error) {
    console.error("Error converting phone number:", error);
    return null;
  }
}
export default convertToE164;
