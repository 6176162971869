import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import ShowLogin from "../views/ShowLogin.vue";
import ShowReporterInfo from "../views/ShowReporterInfo.vue";
import ShowCreateReporter from "../views/admin/ShowCreateReporter.vue";
import ShowReportersList from "../views/admin/ShowReportersList.vue";
import ShowActivityLog from "../views/admin/ShowActivityLog.vue";
import ShowCECredits from "../views/admin/ShowCECredits.vue";

// import CreateGig from "../views/admin/CreateGig.vue";

import { auth } from "../db";

const requireAuth = (to, from, next) => {
  // let user = store.state.user
  let user = auth.currentUser;
  // console.log("current user in reqAuth is ", user);

  if (!user) {
    next({ name: "ShowLogin" });
  } else {
    next();
  }
};

//require no auth to see login / signup screen
// if logged in, user forwared to Gigs Table
const requireNoAuth = (to, from, next) => {
  // let user = store.state.user
  let user = auth.currentUser;

  if (user) {
    next({ name: "HomeView" });
    // next({ name: "ShowReporterInfo", params: { id: user.uid } });
  } else {
    next();
  }
};

// require admin privliges to see page
const requireAdmin = (to, from, next) => {
  // let user = store.state.user
  let user = auth.currentUser;

  user.getIdTokenResult().then((idTokenResult) => {
    // Confirm the user is an Admin.
    if (!idTokenResult.claims.admin) {
      //TODO: something went wrong page
      next({ name: "404" });
    } else {
      next();
    }
  });
};

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "HomeView",
    component: HomeView,
  },
  {
    path: "/login",
    name: "ShowLogin",
    component: ShowLogin,
    beforeEnter: requireNoAuth,
  },
  {
    path: "/reporters",
    name: "ShowReportersList",
    component: ShowReportersList,
    beforeEnter: requireAdmin,
  },
  {
    path: "/ActivityLog",
    name: "ShowActivityLog",
    component: ShowActivityLog,
    beforeEnter: requireAdmin,
  },
  {
    path: "/cecredits",
    name: "ShowCECredits",
    component: ShowCECredits,
    beforeEnter: requireAdmin,
  },
  {
    path: "/reporter/create",
    name: "ShowCreateReporter",
    component: ShowCreateReporter,
    beforeEnter: requireAdmin,
  },
  {
    path: "/reporter/:id",
    name: "ShowReporterInfo",
    component: ShowReporterInfo,
    beforeEnter: requireAuth,
    props: true,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
