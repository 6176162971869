<template>
  <div class="Login mx-auto">
    <v-alert v-if="errorMessage" type="error" :text="true" variant="tonal">{{
      errorMessage
    }}</v-alert>

    <v-form @submit.prevent="handleSubmit">
      <v-text-field
        v-model="userEmail"
        :error-messages="emailErrors"
        label="E-mail"
        required
        @blur="v$.userEmail.$touch()"
      ></v-text-field>

      <v-text-field
        v-model="password"
        :error-messages="passwordErrors"
        :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
        label="Password"
        :type="showPass ? 'text' : 'password'"
        @click:append="showPass = !showPass"
        required
        @blur="v$.password.$touch()"
      ></v-text-field>
      <v-btn type="submit" :disabled="isPending || v$.$invalid">Login</v-btn>
    </v-form>
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
import store from "../store";
import { computed, ref } from "vue";
import router from "@/router";

export default {
  name: "LoginWithEmailAndPasswordLogin",
  setup() {
    const userEmail = ref("");
    const password = ref("");
    const showPass = ref(false);
    const isPending = ref(false);
    const errorMessage = ref(null);

    const rules = {
      userEmail: { required, email },
      password: { required },
    };

    let v$ = useVuelidate(rules, { userEmail, password });

    const emailErrors = computed(() => {
      let errors = [];
      if (v$.value.userEmail.$errors.length) {
        errors.push("Please enter a valid email address.");
        // v$.value.userEmail.$errors.forEach(error => {
        //     errors.push('error.$message')
        // })
      }
      return errors;
    });

    const passwordErrors = computed(() => {
      let errors = [];
      if (v$.value.password.$errors.length) {
        errors.push("Password cannot be blank.");
        // v$.value.password.$errors.forEach(error => {
        //     errors.push(error.$message)
        // })
      }
      return errors;
    });

    const handleSubmit = async () => {
      v$.value.$touch();
      if (!v$.value.$invalid) {
        //submit logic goes here
        try {
          let payload = {
            email: userEmail.value,
            password: password.value,
          };
          errorMessage.value = null;
          isPending.value = true;
          console.log("payload:", payload);
          await store.dispatch("loginWithEmailAndPassword", payload);
          router.go("/reporters");
        } catch (error) {
          isPending.value = false;
          console.log(error);
          // errorMessage.value = "Could not log in using that email and password.";
          errorMessage.value = error.message;
        }
      }
    };

    return {
      showPass,
      userEmail,
      password,
      emailErrors,
      passwordErrors,
      v$,
      handleSubmit,
      isPending,
      errorMessage,
    };
  },
};
</script>

<style>
.Login {
  max-width: 500px;
}
</style>
