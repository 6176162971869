<template>
  <v-container>
    <v-data-table
      v-if="ceRecordItems"
      show-expand
      :headers="headers"
      :items="ceRecordItems"
      :options.sync="options"
      :loading="loading"
      :server-items-length="totalItems"
      @update:options="fetchData"
    >
      <template v-slot:top>
        <v-toolbar elevation="0">
          <v-toolbar-title>CE Credit Records</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn @click="addCECredits">Add New</v-btn>
        </v-toolbar>

        <v-toolbar elevation="0">
          <v-form @submit.prevent="fetchData">
            <v-row>
              <v-col cols="3">
                <v-select
                  dense
                  flat
                  label="Select Field"
                  v-model="searchField"
                  :items="headers"
                  item-text="text"
                  item-value="value"
                  return-object
                  single-line
                ></v-select>
              </v-col>
              <v-col cols="5">
                <v-text-field
                  flat
                  elevation="0"
                  dense
                  hide-details
                  clearable
                  label="Search Text"
                  v-model="searchText"
                  appendIcon="mdi-magnify"
                ></v-text-field>
              </v-col>
              <v-col cols="2">
                <v-checkbox
                  flat
                  elevation="0"
                  dense
                  v-model="searchExact"
                  label="Exact"
                >
                </v-checkbox>
              </v-col>
              <v-col cols="2">
                <v-btn dense type="submit">Search</v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-toolbar>
      </template>
      <template v-slot:[`item.date_earned`]="{ item }">
        {{ format(item.date_earned.toDate(), "P") }}
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <v-row>
            <v-col cols="12" md="12" sm="12">
              <!-- <v-treeview :items="item"></v-treeview> -->
              Data:
              {{ Object.keys(item) }}
              <ul>
                <li v-for="key in Object.keys(item)" :key="key">
                  <!-- {{ key }} -->
                  {{ key }} : {{ item[key] }}
                </li>
              </ul>
            </v-col>
          </v-row>
        </td>
      </template>
      <template v-slot:[`item.isEthics`]="{ item }">
        <v-simple-checkbox
          small
          class="mr-2"
          disabled
          v-model="item.isEthics"
        ></v-simple-checkbox>

        <!-- <v-icon v-if="item.active" small @click="toggleActive(item)">
          mdi-delete </v-icon
        ><v-icon v-else small @click="toggleActive(item)">
          mdi-delete-restore
        </v-icon> -->
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>

        <!-- <v-icon v-if="item.active" small @click="toggleActive(item)">
          mdi-delete </v-icon
        ><v-icon v-else small @click="toggleActive(item)">
          mdi-delete-restore
        </v-icon> -->
      </template>
    </v-data-table>
  </v-container>
</template>
<script setup>
import store from "@/store";
import { computed, ref } from "vue";
import { format } from "date-fns";

const searchText = ref("2019-2020");
const searchField = ref({ text: "Cycle", value: "cycle_code" });
const searchExact = ref(true);

const options = ref({
  groupBy: [],
  groupDesc: [],
  itemsPerPage: 10,
  multiSort: false,
  mustSort: false,
  page: 1,
  sortBy: ["date_earned"],
  sortDesc: [true],
});

const headers = [
  { text: "First", value: "reporter.first_name" },
  { text: "Last", value: "reporter.last_name" },
  { text: "Cycle", value: "cycle_code" },
  { text: "Seminar", value: "seminar_title" },
  { text: "Date Earned", value: "date_earned" },
  { text: "Credits", value: "credits" },
  { text: "Ethics", value: "isEthics" },
  { text: "Actions", value: "actions" },
];

const ceRecordItems = computed(() => {
  console.log("ceRecordItems Computed:", store.state.ceCredits.data);
  return store.state.ceCredits.data.map((item) => {
    return {
      ...item,
    };
  });
  // return store.state.ceRecords.map((item) => {
  //   return { ...item, date_earned: new Date(item.date_earned.seconds * 1000) };
  // });
});
//
const addCECredits = () => {
  let payload = {
    editData: {
      index: -1,

      fieldLabel: "CE Record",
    },
    type: "CERECORD",
  };

  store.commit("setDialogType", payload);
  store.commit("setDialogVisible", true);
};

const editItem = (item) => {
  console.log(item);
  let payload = {
    editData: {
      index: 0,
      payload: {
        ...item,
      },
      recordID: item.id,
      fieldLabel: "CE Record",
    },
    type: "CERECORD",
  };

  store.commit("setDialogType", payload);
  store.commit("setDialogVisible", true);
};

const loading = computed(() => {
  return store.state.ceCredits.loading;
});

const totalItems = computed(() => {
  return store.state.ceCredits.totalItems;
});

const fetchData = () => {
  // console.log("search", searchText.value, searchField.value.value);
  // console.log("options", options.value);
  //  payload has  {collection, mutation, pageOptions, search}
  let payload = {
    collection: "ceRecords",
    mutation: "updateCERecords",
    pageOptions: options.value,
    search: {
      text: searchText.value,
      field: searchField.value.value,
      exact: searchExact.value,
    },
  };

  store.dispatch("ceCredits/bindCollectionPaginated", payload);
};
</script>
