<template>
  <v-container>
    <v-alert v-if="errorMessage" :text="true" type="error" variant="tonal"
      >errorMessage</v-alert
    >

    <div v-if="reporterInfo">
      <v-card>
        <v-card-text>
          <v-row>
            <v-card-title>
              <h3>Reporter Info</h3>
              <v-spacer></v-spacer>
              <v-btn
                v-if="isAdmin"
                color="blue darken-1"
                text
                @click="backToList"
                >Back to List</v-btn
              >
            </v-card-title>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-card-title
                ><h2>
                  {{ reporterInfo.salutation }} {{ reporterInfo.first_name }}
                  {{ reporterInfo.middle_name }} {{ reporterInfo.last_name }}
                  {{ reporterInfo.suffix }}
                </h2>
                <v-spacer></v-spacer>
                <v-btn
                  v-if="isAdmin"
                  @click="editReporter"
                  color="blue darken-1"
                  text
                  >Edit</v-btn
                ></v-card-title
              >
              <v-card-text>
                <v-row>
                  <v-col cols="12" md="6">
                    <p>
                      <span v-if="reporterInfo.DOB"
                        >DOB: {{ format(new Date(reporterInfo.DOB), "P") }}<br
                      /></span>
                      <span v-if="reporterInfo.email"
                        >Primary Email: {{ reporterInfo.email }}<br
                      /></span>
                      <span v-if="reporterInfo.email2"
                        >Backup Email: {{ reporterInfo.email2 }}<br
                      /></span>
                      <span v-if="reporterInfo.phone"
                        >Phone (SMS/Text): {{ reporterInfo.phone }}<br
                      /></span>
                      <span v-if="reporterInfo.phone2"
                        >Alt Phone: {{ reporterInfo.phone2 }}<br
                      /></span>
                    </p>
                  </v-col>
                  <v-col v-if="reporterInfo.status" cols="12" md="6">
                    <h4>Status:</h4>
                    <p>
                      {{ reporterInfo.status.status }} <br />
                      {{ reporterInfo.status.status_date }}
                      <span v-if="reporterInfo.status.status_imported === true"
                        ><br />Status Imported from Access File.</span
                      >
                    </p>
                  </v-col>
                </v-row>
                <v-row v-if="isAdmin">
                  <v-col cols="12">
                    <v-card>
                      <v-card-title
                        ><h4>Notes:</h4>
                        <v-spacer></v-spacer
                        ><v-btn @click="editNotes" color="blue darken-1" text
                          >Edit</v-btn
                        ></v-card-title
                      >
                      <v-card-text>{{ reporterInfo.notes }}</v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-col>
          </v-row>
          <v-card-text>
            <v-row class="AddressRows">
              <v-col cols="12" sm="12" md="6">
                <ShowAddress
                  :address="reporterInfo.home_address"
                  fieldLabel="Home Address"
                  fieldName="home_address"
                  :recordID="id"
                />
              </v-col>

              <v-col cols="12" sm="12" md="6">
                <ShowAddress
                  :address="reporterInfo.work_address"
                  fieldLabel="Work Address"
                  fieldName="work_address"
                  :recordID="id"
                />
              </v-col>
            </v-row>

            <v-row class="CertInfo">
              <v-col cols="12">
                <v-card-title><h4>Certifications</h4></v-card-title>
              </v-col>

              <v-card-text>
                <v-row>
                  <v-col cols="12" sm="12" md="5">
                    <v-card-text>
                      <div v-if="reporterInfo.CCR">
                        <p>
                          CCR# {{ reporterInfo.CCR.certNumber }}<br />Earned:
                          {{
                            format(new Date(reporterInfo.CCR.certDate), "PP")
                          }}
                          - {{ reporterInfo.CCR.certHow }}<br />Method:
                          {{ reporterInfo.CCR.certMethod }}<br />
                        </p>
                      </div>
                      <div v-else>
                        <show-add-cert
                          certType="CCR"
                          :reporterId="id"
                        ></show-add-cert>
                      </div>
                    </v-card-text>
                  </v-col>
                  <v-col cols="12" sm="12" md="5">
                    <v-card-text>
                      <div v-if="reporterInfo.CDR">
                        <p>
                          CDR# {{ reporterInfo.CDR.certNumber }}<br />Earned:
                          {{
                            format(new Date(reporterInfo.CDR.certDate), "PP")
                          }}
                          - {{ reporterInfo.CDR.certHow }}<br />Method:
                          {{ reporterInfo.CDR.certMethod }}<br />
                        </p>
                      </div>
                      <div v-else>
                        <show-add-cert
                          certType="CDR"
                          :reporterId="id"
                        ></show-add-cert>
                      </div> </v-card-text></v-col
                ></v-row>
                <v-row>
                  <v-col cols="12">
                    <v-card-title><h4>Continuing Education</h4></v-card-title>
                  </v-col>
                  <v-col cols="12">
                    <v-card-text>
                      <p v-if="reporterCE">
                        Credits for current 2023-2024 CE Cycle:
                        {{ reporterCETotal }}
                      </p>
                      <p v-if="reporterInfo.CE_exempt">
                        {{ reporterInfo.CE_exempt }}
                      </p>
                    </v-card-text>
                  </v-col>
                  <v-col cols="12">
                    <v-data-table
                      v-if="reporterCE"
                      sort-desc
                      :headers="ceHeaders"
                      :items="reporterCE"
                      :sort-by="['date_earned']"
                    >
                      <template v-slot:[`item.date_earned`]="{ item }">
                        {{ format(item.date_earned, "PP") }}
                      </template>
                    </v-data-table>
                    <p v-else>No CE Data Available.</p>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-row>
          </v-card-text>

          <v-row v-if="isAdmin">
            <v-col cols="12">
              <v-card-text>
                <ShowRecordInfo :record="reporterInfo" />
              </v-card-text>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </div>

    <div v-else>Loading...</div>
  </v-container>
</template>

<script setup>
import { ref, computed, onMounted } from "vue";
import store from "@/store";
import router from "@/router";
import { format } from "date-fns";
import ShowAddCert from "@/components/ShowAddCert.vue";
import ShowRecordInfo from "@/components/ShowRecordInfo.vue";
import ShowAddress from "@/components/ShowAddress.vue";
// export default {
//   name: "ShowReporterInfo",
//   components: { ShowAddCert, ShowRecordInfo, ShowAddress },
//   props: {
//     id: { type: String, required: true },
//   },
//   setup(props) {
const props = defineProps({
  id: { type: String, required: true },
});
console.log("showReporterInfo Setup");
const isAdmin = computed(() => {
  return store.state.user.claims.admin ? true : false;
});
const backToList = () => {
  router.push({ name: "ShowReportersList" });
};

const errorMessage = ref(null);
// const reporterInfo = ref(null)

onMounted(() => {
  // bind reporter doc if admin and not alredy in reporters array

  //no reporter list, bind document
  store.dispatch("bindDocument", {
    collection: "reporters",
    document: props.id,
    mutation: "updateReporter",
  });
});
// if reporters has lenght no need to document is in the array
const reporterInfo = computed(() => {
  return store.state.reporter;
});

store.dispatch("bindCECreditCollectionWithQuery", { reporterID: props.id });
const reporterCETotal = computed(() => {
  let total = 0;
  if (store.state.reporterCERecords.length) {
    store.state.reporterCERecords.forEach((record) => {
      if (record.cycle_code == "2023-2024") {
        total += Number(record.credits);
      }
    });
    return total;
  } else {
    return total;
  }
});

const reporterCE = computed(() => {
  if (store.state.reporterCERecords.length) {
    return store.state.reporterCERecords.map((item) => {
      return {
        ...item,
        date_earned: new Date(item.date_earned.seconds * 1000),
      };
    });
  } else {
    return null;
  }
});
const editNotes = () => {
  let payload = {
    editData: {
      payload: {
        notes: reporterInfo.value.notes,
      },
      recordID: props.id,
    },
    type: "USERNOTES",
  };

  store.commit("setDialogType", payload);
  store.commit("setDialogVisible", true);
};

const editReporter = () => {
  let payload = {
    editData: {
      index: 0,
      payload: {
        email: reporterInfo.value.email,
        email2: reporterInfo.value.email2,
        phone: reporterInfo.value.phone,
        phone2: reporterInfo.value.phone2,
        salutation: reporterInfo.value.salutation,
        middle_name: reporterInfo.value.middle_name,
        other_name: reporterInfo.value.other_name,
        first_name: reporterInfo.value.first_name,
        last_name: reporterInfo.value.last_name,
        suffix: reporterInfo.value.suffix,
        DOB: reporterInfo.value.DOB,
      },
      recordID: reporterInfo.value.id,
      fieldLabel: "Reporter",
    },
    type: "USERRECORD",
  };

  store.commit("setDialogType", payload);
  store.commit("setDialogVisible", true);
};

// return {
//   reporterInfo,
//   errorMessage,
//   isAdmin,
// };
//   },
// };
const ceHeaders = [
  { text: "Seminar", value: "seminar_title" },
  { text: "Cycle", value: "cycle_code" },
  { text: "Date Earned", value: "date_earned" },
  { text: "Credits", value: "credits" },
  { text: "Ethics", value: "isEthics" },
];
</script>

<style></style>
