<template>
  <v-card-text>
    <v-row>
      <v-form>
        <v-row>
          <v-col>
            <v-select
              dense
              flat
              label="Find By"
              v-model="lookup.searchField"
              :items="lookupfindByList"
              item-text="text"
              item-value="value"
              return-object
              single-line
            ></v-select
          ></v-col>

          <v-col cols="5">
            <v-text-field
              flat
              elevation="0"
              dense
              hide-details
              clearable
              label="Search Text"
              v-model="lookup.searchText"
              appendIcon="mdi-magnify"
            ></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-checkbox
              flat
              elevation="0"
              dense
              v-model="lookup.searchExact"
              label="Exact"
            >
            </v-checkbox>
          </v-col>
          <v-col cols="2">
            <v-btn
              dense
              color="blue darken-1"
              text
              :disabled="invalid"
              @click="handleLookupReporter"
              >Search</v-btn
            >
          </v-col>
        </v-row>
      </v-form>
    </v-row>
    <v-row v-if="lookupResults.length">
      <p v-for="result in lookupResults" :key="result.id">
        {{ result.first_name }} {{ result.last_name }} - {{ result.id }}
        <br /><span v-if="result.CCR"
          >CCR: {{ result.CCR.certNumber }} <br
        /></span>
        <span v-if="result.CDR">CDR: {{ result.CDR.certNumber }}<br /></span>
        <v-btn color="blue darken-1" text @click="selectReporter(result)"
          >Select</v-btn
        >
      </p>
    </v-row>
  </v-card-text>
</template>

<script setup>
import { db } from "../db";
import store from "../store";
import {
  collection,
  query,
  where,
  and,
  or,
  getDocs,
} from "@firebase/firestore";
import { ref, watchEffect } from "vue";

const lookup = ref({
  searchExact: true,
  searchText: "",
  searchField: "",
});
const handleLookupReporter = async () => {
  console.log("handleLookupReporter");
  console.log(lookup.value);
  lookupResults.value = [];
  let q;

  const collectionRef = collection(db, "reporters");

  let qWhere;
  if (lookup.value.searchField.value == "email") {
    if (lookup.value.searchExact) {
      console.log("field email, exact");
      qWhere = or(
        where("email", "==", lookup.value.searchText),
        where("email2", "==", lookup.value.searchText)
      );
    } else {
      console.log("field email, not exact");
      let qWhere1 = or(
        //query as is
        and(
          where("email", ">=", lookup.value.searchText),
          where("email", "<=", lookup.value.searchText + "\uf8ff")
        ),
        //capitalize first letter
        and(
          where(
            "email",
            ">=",
            lookup.value.searchText.charAt(0).toUpperCase() +
              lookup.value.searchText.slice(1)
          ),
          where(
            "email",
            "<=",
            lookup.value.searchText.charAt(0).toUpperCase() +
              lookup.value.searchText.slice(1) +
              "\uf8ff"
          )
        ),
        // lowercase
        and(
          where("email", ">=", lookup.value.searchText.toLowerCase()),
          where("email", "<=", lookup.value.searchText.toLowerCase() + "\uf8ff")
        )
      );
      let qWhere2 = or(
        //query as is
        and(
          where("email2", ">=", lookup.value.searchText),
          where("email2", "<=", lookup.value.searchText + "\uf8ff")
        ),
        //capitalize first letter
        and(
          where(
            "email2",
            ">=",
            lookup.value.searchText.charAt(0).toUpperCase() +
              lookup.value.searchText.slice(1)
          ),
          where(
            "email2",
            "<=",
            lookup.value.searchText.charAt(0).toUpperCase() +
              lookup.value.searchText.slice(1) +
              "\uf8ff"
          )
        ),
        // lowercase
        and(
          where("email2", ">=", lookup.value.searchText.toLowerCase()),
          where(
            "email2",
            "<=",
            lookup.value.searchText.toLowerCase() + "\uf8ff"
          )
        )
      );
      try {
        let q1 = query(collectionRef, qWhere1);
        let q2 = query(collectionRef, qWhere2);

        let q1Snap = await getDocs(q1);
        let q2Snap = await getDocs(q2);

        q1Snap.forEach((doc) => {
          lookupResults.value.push({ ...doc.data(), id: doc.id });
        });
        q2Snap.forEach((doc) => {
          lookupResults.value.push({ ...doc.data(), id: doc.id });
        });
      } catch (err) {
        console.log(err);
      }
      console.log(lookupResults.value);
      return;
    }
  } else {
    //all fieds other than email
    if (lookup.value.searchExact) {
      //   console.log("exact field other than email");
      qWhere = where(
        lookup.value.searchField.value,
        "==",
        lookup.value.searchText
      );
    } else {
      //   console.log("field other than email, not exact");
      qWhere = or(
        //query as is
        and(
          where(lookup.value.searchField.value, ">=", lookup.value.searchText),
          where(
            lookup.value.searchField.value,
            "<=",
            lookup.value.searchText + "\uf8ff"
          )
        ),
        //capitalize first letter
        and(
          where(
            lookup.value.searchField.value,
            ">=",
            lookup.value.searchText.charAt(0).toUpperCase() +
              lookup.value.searchText.slice(1)
          ),
          where(
            lookup.value.searchField.value,
            "<=",
            lookup.value.searchText.charAt(0).toUpperCase() +
              lookup.value.searchText.slice(1) +
              "\uf8ff"
          )
        ),
        // lowercase
        and(
          where(
            lookup.value.searchField.value,
            ">=",
            lookup.value.searchText.toLowerCase()
          ),
          where(
            lookup.value.searchField.value,
            "<=",
            lookup.value.searchText.toLowerCase() + "\uf8ff"
          )
        )
      );
    }
  }
  q = query(collectionRef, qWhere);

  try {
    const snap = await getDocs(q);
    snap.forEach((doc) => {
      lookupResults.value.push({ ...doc.data(), id: doc.id });
    });
  } catch (err) {
    console.log(err);
  }
  console.log(lookupResults.value);
};
const lookupResults = ref([]);
const lookupfindByList = [
  { text: "CCR", value: "CCR.certNumber" },
  { text: "CDR", value: "CDR.certNumber" },
  { text: "First Name", value: "first_name" },
  { text: "Last Name", value: "last_name" },
  { text: "Email", value: "email" },
];

const selectReporter = (result) => {
  let reporter = {
    CCR: result.CCR ? result.CCR.certNumber : "",
    CDR: result.CDR ? result.CDR.certNumber : "",
    first_name: result.first_name,
    last_name: result.last_name,
    email: result.email,
    id: result.id,
  };
  store.commit("setNewCERecord", reporter);

  console.log(reporter);
};
const invalid = ref(true);

watchEffect(() => {
  if (
    (lookup.value.searchField == "") |
    (lookup.value.searchText == "") |
    (lookup.value.searchText === null)
  ) {
    invalid.value = true;
  } else {
    invalid.value = false;
  }
});
</script>
