// import { firestoreAction } from "vuexfire";

import { db, auth } from "../db";
import {
  collection,
  query,
  where,
  onSnapshot,
  addDoc,
  updateDoc,
  doc,
  serverTimestamp,
} from "@firebase/firestore";
import { signInWithEmailAndPassword } from "firebase/auth";
import { watchEffect } from "vue";
import store from ".";

// import router from "../router";

const actions = {
  async logout() {
    try {
      await auth.signOut();
      console.log("user logged out");
    } catch (err) {
      console.log(err.message);
    }
  },

  async loginWithEmailAndPassword(context, payload) {
    try {
      // console.log(payload);
      await signInWithEmailAndPassword(auth, payload.email, payload.password);
      return;
    } catch (err) {
      if (err.code == "auth/user-not-found") {
        throw new Error(
          "User not found. Log in by email only works for the user's primary email address."
        );
      } else {
        throw new Error("Unable to login with the supplied credentials");
      }
    }
  },

  async bindCECreditCollectionWithQuery(context, payload) {
    return new Promise((resolve, reject) => {
      console.log(
        `bindCollection called for CERecords of ${payload.reporterID}`
      );
      console.log(payload);
      try {
        const q = query(
          collection(db, "ceRecords"),
          where("reporter.id", "==", payload.reporterID)
        );
        const unsub = onSnapshot(q, (snap) => {
          console.log("snapshot");
          let items = [];
          snap.forEach((doc) => {
            items.push({ id: doc.id, ...doc.data() });
          });
          context.commit("updateReporterCERecords", items);
          console.log(`update reporterCERecords store updated`);
        });
        watchEffect((onCleanup) => {
          onCleanup(() => {
            console.log(`unsubscribing reporterCERecords`);
            context.commit("updateReporterCERecords", []);
            unsub();
          });
        });
        console.log(unsub);
        resolve(unsub);
      } catch (error) {
        reject(error);
      }
    });
  },

  async bindCollection(context, payload) {
    return new Promise((resolve, reject) => {
      console.log(`bindCollection called for ${payload.collection}`);
      try {
        const collectionRef = collection(db, payload.collection);
        // let items = store.state[payload.collection];
        let items = [];
        const unsub = onSnapshot(collectionRef, (snap) => {
          console.log("snapshot");
          snap.docChanges().forEach((change) => {
            let index = items.findIndex(({ itemID }) => {
              return itemID === change.doc.id;
            });
            if (change.type === "added") {
              console.log("Added Doc:", payload.collection, change.doc.data());
              items.push({ id: change.doc.id, ...change.doc.data() });
            }
            if (change.type === "modified") {
              console.log(
                "Modified doc:",
                payload.collection,
                change.doc.data()
              );
              items[index] = { ...change.doc.data() };
            }
            if (change.type === "removed") {
              console.log(
                "Removed Doc:",
                payload.collection,
                change.doc.data()
              );
              items.splice(index, 1);
            }
          });

          // forEach((doc) => {});
          context.commit(payload.mutation, items);
          console.log(`${payload.mutation} store updated`);
        });
        watchEffect((onCleanup) => {
          onCleanup(() => {
            console.log(`unsubscribing ${payload.collection}`);
            unsub();
          });
        });
        console.log(unsub);
        resolve(unsub);
      } catch (error) {
        reject(error);
      }
    });
  },

  async bindDocument(context, payload) {
    return new Promise((resolve, reject) => {
      console.log(
        `bindDocument called for ${payload.collection}/${payload.document}`
      );
      try {
        const docRef = doc(db, payload.collection, payload.document);
        const unsub = onSnapshot(docRef, (snap) => {
          console.log("snapshot");
          console.log(snap.data());
          context.commit(payload.mutation, {
            ...snap.data(),
            id: payload.document,
          });
          if (snap.data() == undefined) {
            console.log("Document not found");
          }
        });
        watchEffect((onCleanup) => {
          onCleanup(() => {
            console.log(
              `unsubscribing ${payload.collection}/${payload.document}`
            );
            unsub();
          });
        });
        resolve(unsub);
      } catch (error) {
        reject(error);
      }
    });
  },

  async dbCreate(context, payload) {
    console.log(payload);
    try {
      let docRef = await addDoc(collection(db, payload.collection), {
        ...payload.doc,
        createdAt: serverTimestamp(),
        createdBy: {
          displayName: store.state.user.displayName,
          uid: store.state.user.uid,
        },
      });
      console.log(docRef);
      await addDoc(collection(db, "activity-log"), {
        record: docRef.id,
        user: {
          displayName: store.state.user.displayName,
          uid: store.state.user.uid,
        },
        timestamp: serverTimestamp(),
        activity: "created",
        collection: payload.collection,
      });
      return docRef;
    } catch (err) {
      console.log(err.message);
      return err;
    }
  },

  async dbUpdate(context, payload) {
    console.log("dbUpdate doc:", payload);
    try {
      await updateDoc(doc(db, payload.collection, payload.id), {
        ...payload.doc,
        updatedAt: serverTimestamp(),
        updatedBy: {
          displayName: store.state.user.displayName,
          uid: store.state.user.uid,
        },
      });

      await addDoc(collection(db, "activity-log"), {
        record: payload.id,
        collection: payload.collection,
        data: payload.doc,
        user: {
          displayName: store.state.user.displayName,
          uid: store.state.user.uid,
        },
        timestamp: serverTimestamp(),
        activity: "updated",
      });
      return "updated";
    } catch (err) {
      console.log(err.message);
      throw err;
    }
  },
};

export default actions;
